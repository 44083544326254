// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-list-jsx": () => import("../src/templates/article-list.jsx" /* webpackChunkName: "component---src-templates-article-list-jsx" */),
  "component---src-templates-course-list-jsx": () => import("../src/templates/course-list.jsx" /* webpackChunkName: "component---src-templates-course-list-jsx" */),
  "component---src-templates-class-list-jsx": () => import("../src/templates/class-list.jsx" /* webpackChunkName: "component---src-templates-class-list-jsx" */),
  "component---src-templates-article-list-category-jsx": () => import("../src/templates/article-list-category.jsx" /* webpackChunkName: "component---src-templates-article-list-category-jsx" */),
  "component---src-templates-page-show-jsx": () => import("../src/templates/page-show.jsx" /* webpackChunkName: "component---src-templates-page-show-jsx" */),
  "component---src-templates-article-show-jsx": () => import("../src/templates/article-show.jsx" /* webpackChunkName: "component---src-templates-article-show-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

