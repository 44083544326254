import { filePathToPath } from '~/libs/common/helper'

function extractArticle({ context: { id, to, title } }) {
    return {
        id,
        title,
        to
    }
}

export {
    filePathToPath
}

export const ONE_DAY = 86400000

export function isFutureDate(date, day = -1) {
    return (new Date(date) - new Date()) >= (ONE_DAY * day)
}

export function truncate(text, length = 25) {
    return `${text.split('').slice(0, length).join('')} ...`
}

export function shuffle(arr) {
    return arr
        .map(each => [Math.random(), each])
        .sort((a, b) => a[0] - b[0])
        .map(each => each[1])
}

export function transformSitePage(nodes) {
    const transformNodes = nodes.map(({ path: to, context }) => {
        const title = context ? context.title : ''

        return {
            to,
            title
        }
    })

    return transformNodes.reduce((acc, each) => {
        const { to, title } = each
        let fieldName

        switch (true) {
            case /category\//.test(to):
                fieldName = 'categories'
                break

            case /course\//.test(to):
                fieldName = 'courses'
                break

            case /class\//.test(to):
                fieldName = 'classes'
                break

            default:
                return acc
        }

        return !title
            ? acc
            : {
                ...acc,
                [fieldName]: [...acc[fieldName], each]
            }
    }, { categories: [], courses: [], classes: [] })
}

export function generateRelateArticles(articles) {
    return articles.reduce((acc, { context: { id } }, idx, arr) => {
        const prev = idx > 0 ? extractArticle(arr[idx - 1]) : null
        const next = idx < arr.length - 1 ? extractArticle(arr[idx + 1]) : null

        return {
            ...acc,
            [id]: {
                prev,
                next
            }
        }
    }, {})
}

export function appendScript(script, handleScriptLoad) {
    if (!document) {
        return
    }

    if (!document.getElementById(script.id)) {
        const el = document.createElement('script')

        el.id = script.id
        el.async = true
        el.defer = true
        el.src = script.src

        if (handleScriptLoad) {
            el.onload = handleScriptLoad
        }

        if (!script.delay) {
            document.body.appendChild(el)
        } else {
            setTimeout(() => {
                document.body.appendChild(el)
            }, script.delay)
        }
    }
}

