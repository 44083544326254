
function filePathToPath(fileAbsolutePath) {
    const base = /articles\//.test(fileAbsolutePath)
        ? '/article'
        : /courses\//.test(fileAbsolutePath)
            ? '/course'
            : /classes\//.test(fileAbsolutePath)
                ? '/class'
                : ''

    return `${base}/${fileAbsolutePath.split('/').pop().replace(/\.md/, '')}`
}

module.exports = {
    filePathToPath
}