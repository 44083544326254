const React = require('react')
const { Provider } = require('react-redux')

const Layout = require('~/components/layout').default
const { default: store, SSR_SET } = require('~/store')

exports.wrapPageElement = ({ props, element }) => {
    store.dispatch({ type: SSR_SET, payload: false })

    return <Provider store={store}>
        {
            !/404-jsx$/.test(props.pageResources.page.componentChunkName)
                ? <Layout {...props}>{element}</Layout>
                : <>{element}</>
        }
    </Provider>
}