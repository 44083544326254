import React from 'react'
import { Link } from 'gatsby'

export default function NavbarItemDropdown({ text, items, deactivateNavbar }) {
    function handleClick(e) {
        e.target.blur()
        deactivateNavbar()
    }

    return <div className="navbar-item has-dropdown is-hoverable">
        <a className="navbar-link">{text}</a>

        <div className="navbar-dropdown">
            {items.map((each, idx) => <Link onClick={handleClick} key={idx} to={each.to} className="navbar-item">{each.title}</Link>)}
        </div>
    </div>
}