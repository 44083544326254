import { createStore } from 'redux'

export const SSR_SET = 'SSR_SET'
export const NAVBAR_SET = 'NAVBAR_SET'
export const RELATE_ARTICLE_SET = 'RELATE_ARTICLE_SET'

const initState = {
    isSSR: true,
    categories: [],
    courses: [],
    classes: [],
    relateArticles: []
}

const reducer = (state = initState, { type, payload }) => {
    switch (type) {
        case SSR_SET:
            return { ...state, isSSR: payload }

        case NAVBAR_SET:
            return { ...state, categories: payload.categories, courses: payload.courses, classes: payload.classes }

        case RELATE_ARTICLE_SET:
            return { ...state, relateArticles: payload }

        default:
            return state
    }
}

export default createStore(reducer)
