import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'

import { getDevice } from '~/libs/es/device'
import { socialIcons, links } from '~/libs/es/navbar'

import NavbarItem from 'components/navbar-item'
import NavbarItemDropdown from 'components/navbar-item-dropdown'

function mapStateToProps({ device, categories, courses, classes }) {
    return { device, categories, courses, classes }
}

export default connect(mapStateToProps)(function ({ categories = [], courses = [], classes = [], siteMetadata }) {
    const [navbarActive, setNavbarActive] = useState(false)
    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        if (getDevice() === 'phone') {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }, [])

    function deactivateNavbar() {
        setNavbarActive(false)
    }

    function toggleNavbarActive() {
        setNavbarActive(prevnavbarActive => !prevnavbarActive)
    }

    function transformDesktopLinks(each) {
        if (!each.id) return each

        return {
            ...each,
            items: each.id === 'course'
                ? courses
                : each.id === 'class'
                    ? classes
                    : categories
        }
    }

    function transformMobileLinks(each) {
        if (!each.id) return each

        return {
            ...each,
            items: each.id === 'category' && categories
        }
    }

    return <nav className="navbar is-fixed-top">
        <div className="navbar-brand" style={isPhone ? { marginTop: '5px', marginLeft: '20px' } : { marginLeft: '25px' }}>
            <Link to="/" className="navbar-item" style={isPhone ? { paddingLeft: '0px' } : {}}>
                <img src="/images/brand.png" alt={`${siteMetadata.title} | ${siteMetadata.description}`} />
            </Link>

            {
                socialIcons.map(({ link, color, icon }) => <a key={link} className="navbar-item is-hidden-desktop" href={link} target="_blank">
                    <span className="icon" style={{ color: `#${color}` }}>
                        <i className={`fa fa-lg ${icon}`}></i>
                    </span>
                </a>)
            }

            <button onClick={toggleNavbarActive} className={`button navbar-burger ${navbarActive ? 'is-active' : ''}`} style={{ height: '30px', width: '40px', marginTop: '10px', marginRight: '15px' }}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>

        <div className={`navbar-menu ${navbarActive ? 'is-active' : ''}`}>
            {
                links
                    .map(isPhone ? transformMobileLinks : transformDesktopLinks)
                    .map((each, idx) => !each.items
                        ? <NavbarItem key={idx} {...each} deactivateNavbar={deactivateNavbar} />
                        : <NavbarItemDropdown key={idx} {...each} deactivateNavbar={deactivateNavbar} />
                    )
            }
        </div>
    </nav>
})