export const getProps = ({ isCurrent, href, location: { pathname } }) => {
    const isArticle = (/\/article/.test(pathname) || /\/category/.test(pathname)) && /\/article/.test(href)
    const className = `navbar-item is-tab ${isCurrent || isArticle ? 'is-active' : ''}`

    return { className }
}

export const socialIcons = [
    { color: '55acee', icon: 'fa-twitter', link: 'https://twitter.com/tutor4dev' },
    { color: '4267b2', icon: 'fa-facebook', link: 'https://fb.com/tutor4dev' },
    { color: 'ff0000', icon: 'fa-youtube', link: 'https://www.youtube.com/user/tutor4dev/videos' }
]

export const links = [
    { text: 'หน้าหลัก', to: '/' },
    { text: 'YouTube', to: 'https://www.youtube.com/user/tutor4dev/videos', external: true },
    { text: 'บทความ', to: '/article' },
    { text: 'คอร์สอบรมองค์กร', to: '/course', items: [], id: 'course' },
    { text: 'คอร์สอบรม', to: '/class', items: [], id: 'class' },
    { text: 'หมวดหมู่', items: [], id: 'category' },
    { text: 'เกี่ยวกับเรา', to: '/about' }
]
